import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    const fetchLocationData = () => {
      fetch('/api/location')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setCountry(decodeURIComponent(data.country));
          setCity(decodeURIComponent(data.city));
          
          // Remove numbers from city and country names
          const cleanCity = data.city.replace(/[0-9]/g, '');
          const cleanCountry = data.country.replace(/[0-9]/g, '');
  
          const address = `${encodeURIComponent(cleanCity)}, ${encodeURIComponent(cleanCountry)}`;
          const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBTT1Epe-qKRGcLSPos2waBZ-2M5dkMfew`;
          console.log("URL Requested: ", url); // Log the URL
          return axios.get(url);
        })
        .then(response => {
          console.log("Google Geocoding API Response: ", response.data); // Log the full response
          const results = response.data.results;
          if (results.length > 0) {
            const addressComponents = results[0].address_components;
            const stateObj = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
            if (stateObj) {
              setRegion(stateObj.long_name);
            }
          }
          setFetchAttempted(true);
        })
        .catch(error => {
          console.error('Fetch error:', error);
          setCountry('Unavailable');
          setRegion('');
          setCity('');
          setFetchAttempted(true);
        });
    };
  
    if (!region && !fetchAttempted) {
      fetchLocationData();
    }
  }, [region]); // Dependency on region


  return (
    <div className="container">

    <img src='https://i.ibb.co/Fx66Y3s/photo-2024-02-20-11-40-25-1.jpg' alt="Content Image" className="emmaimage"/>

    <div className='text--centered'>
              <span>
              💜 18+ BBW Creator 💜
              </span>
            </div>



      <div className='main__message--bubble'>
      
        <section>
            <div className='flexRow'>
              <span>
              Im curently in {city}, {region}   {country && <img 
                  src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                  alt={`${country} Flag`}
                  style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
                />}   looking for someone to make content with me😊
              </span>
            </div>
        </section>
        
        
      </div>

      <a href="https://onlyfans.com/bigtittyemmafree/c19" id="customButton">
      ✨𝐅𝐑𝐄𝐄 𝐎𝐍𝐋𝐘𝐅𝐀𝐍𝐒✨
      </a>
      <Analytics/>
    </div>
  );
}

export default App;
